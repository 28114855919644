<template>
    <div class="flex justify-center px-4">
      <div class="w-full max-w-md bg-white p-8 shadow rounded-lg flex flex-col items-center pt-4 mt-5">
        <p>{{ path }}</p>
        <form class="w-full" v-if="path">
          <div>
            <label class="block text-sm font-bold mb-2" for="roles">
              Rollen
            </label>
            <multiselect v-if="allRoles" :multiple="true" v-model="roles" deselect-label="Verwijder" select-label="Selecteer" selected-label="Geselecteerd" placeholder="Selecteer rollen" track-by="name" label="name" :options="allRoles" :searchable="false" :allow-empty="true">
            </multiselect>
          </div>
          <div class="mt-4">
            <p v-if="error" class="text-primary text-sm font-semibold">{{ error }}</p>
          </div>
          <div class="rounded-lg text-white px-4 py-3 text-sm cursor-pointer bg-primary text-center mt-4" @click="editFolder()">
            Opslaan
          </div>
        </form>
      </div>
    </div>
</template>

<script>

export default {
  title: 'Folder aanpassen',
  name: 'EditFolder',
  data () {
    return {
      path: null,
      accessDirectories: null,
      folder: null,
      roles: [],
      allRoles: null,
      error: null
    }
  },
  mounted () {
    if (!this.$store.getters.rootDirectories) {
      this.$store
        .dispatch('getFiles')
        .then(() => this.setFolder())
        .catch(() => this.setError('Er is iets misgegaan'))
    } else this.setFolder()
  },
  methods: {
    setFolder () {
      this.path = this.$store.getters.rootDirectories.find(folder => folder === this.$route.params.path)
      if (!this.path) this.$router.push({ path: '/folders' }).catch(() => {})
      this.folder = this.$store.getters.accessDirectories.find(folder => folder.path === this.$route.params.path)
      if (!this.$store.getters.allRoles) {
        this.$store
          .dispatch('getRoles')
          .then(() => this.setRoles())
          .catch(() => this.setError('Er is iets misgegaan'))
      } else this.setRoles()
    },
    setRoles () {
      this.allRoles = this.$store.getters.allRoles.filter(role => role.name !== 'Admin')
      if (this.folder) {
        this.folder.roles.forEach(role => {
          if (role !== 'Admin') this.roles.push(this.allRoles.find(newRole => newRole.name === role))
        })
      }
    },
    editFolder () {
      const roles = this.roles.map(role => role.id)
      const path = this.path
      this.$store
        .dispatch('setFolderRole', { path, roles })
        .then(() => this.$router.push('/folders'))
        .catch(err => this.setError(err))
    },
    setError (data) {
      this.error = data
    }
  }
}
</script>
